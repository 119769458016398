/*import { bootstrapApplication } from '@angular/platform-browser'
import { appConfig } from '@app/app.config'
import { AppComponent } from '@app/app.component'

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err))*/

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import * as Sentry from "@sentry/angular"
import { environment } from '@env/environment'

Sentry.init({
	enabled: environment.production,
	dsn: "https://f3e458c3f536d1f0cd3ccb894684a993@o4507736201887744.ingest.de.sentry.io/4507736206409808",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration( {
			maskAllText: false,
			blockAllMedia: false
		}),
		Sentry.replayCanvasIntegration(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/backoffice\.tab\.ruhr/],
	// Session Replay
	replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err))
